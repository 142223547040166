import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { useForm, ValidationError } from '@formspree/react';
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"

const Contact = ({ data }) => {
  const [state, handleSubmit] = useForm("xknkgpwy");

  return (
    <Layout>
      <SiteMetadata title="Contact" description="Contact us. Request a session" />

      <div className="bg-gray-100">
        <div className="container py-12 lg:pb-16">
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 xl:w-3/5 pb-8 md:pb-0">
              <h2 className="text-xl leading-tight font-semibold tracking-tight text-blue-600 sm:text-2xl">
                Send me a note!
              </h2>

              {state.succeeded
              ? (
                  <p>Thank you for reaching out. I will be in touch with you soon!</p>
                )
              : (
                  <form onSubmit={handleSubmit} className="pt-6 pb-8 mb-4">
                    <input type="hidden" name="_subject" id="email-subject" value="SSB Test Contact Form
                    Submission" />
                    <input type="text" name="_gotcha" style={{ display: "none" }} />

                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                        Name
                      </label>
                      <input required className="shadow appearance-none w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="name" id="name" type="text" placeholder="Your Name" />
                      <ValidationError
                        prefix="Name"
                        field="name"
                        errors={state.errors}
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                        Email
                      </label>
                      <input required className="shadow appearance-none w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="email" id="email" type="text" placeholder="your@email.com" />
                      <ValidationError
                        prefix="Email"
                        field="email"
                        errors={state.errors}
                      />
                    </div>
                    <div className="mb-6">
                      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
                        Message
                      </label>
                      <textarea required name="message" id="message" className="shadow appearance-none w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" rows="4" placeholder="Hello!" />
                      <ValidationError
                        prefix="Message"
                        field="message"
                        errors={state.errors}
                      />
                    </div>
                    <div className="flex items-center justify-between">
                      <button disabled={state.submitting} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Send
                      </button>
                    </div>
                  </form>
                )
              }
            </div>
            <div className="w-full md:w-1/2 xl:w-2/5 md:pl-12">
              <Img
                fluid={data.author.childImageSharp.fluid}
                alt="Patricia Linke"
                className="rounded-md shadow-md"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query {
    author: file(relativePath: { eq: "author2.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 480, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
